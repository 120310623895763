@font-face {
  font-family: 'Chasten-Medium';
  src: local('Gotham-Rounded'), url(./fonts/GothamRoundedMedium_21022.ttf) format('truetype');
}
@font-face {
  font-family: 'Chasten-Book';
  src: local('Gotham-Rounded'), url(./fonts/GothamRoundedBook_21018.ttf) format('truetype');
}
@font-face {
  font-family: 'Chasten-Light';
  src: local('Gotham-Rounded'), url(./fonts/GothamRoundedLight_21020.ttf) format('truetype');
}
@font-face {
  font-family: 'Chasten-Bold';
  src: local('Gotham-Rounded'), url(./fonts/GothamRoundedBold_21016.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
